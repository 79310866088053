<template>
  <div class="openClass">
    <el-radio-group v-model="tableType"
                    @change="tableTypeChange">
      <el-radio-button :label=1
                       v-if="is_no!==2">公开课管理</el-radio-button>
      <el-radio-button :label=2>公开课评分</el-radio-button>
    </el-radio-group>
    <!-- <div> -->
    <div class="query-header-button"
         v-if="tableType===1">
      <el-button type="success"
                 @click="addOpenClassClick">新增公开课</el-button>
      <el-button type="primary"
                 v-if="roleType===1"
                 @click="teacherClassRanking(1)">教师课时排名</el-button>
      <el-button type="primary"
                 v-if="roleType===1"
                 @click="teacherClassRanking(2,1)">评分教师课时排名</el-button>
      <el-button type="info"
                 @click="exporOpenClass">批量导出</el-button>
    </div>
    <div class="query-header">
      <term-selector placeholder="请选择学期"
                     class="picker"
                     @change="querySemesterChange"
                     v-model="querySemester" />
      <el-date-picker v-model="queryTime"
                      type="daterange"
                      class="picker"
                      range-separator="至"
                      value-format="yyyy-MM-dd"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
      </el-date-picker>
      <el-select placeholder="请选择状态"
                 class="picker"
                 v-if="tableType===1"
                 v-model="queryStatus">
        <el-option v-for="status in statusList"
                   :key="status.value"
                   :label="status.name"
                   :value="status.value"></el-option>
      </el-select>
      <el-select placeholder="请选择状态"
                 class="picker"
                 v-if="tableType===2"
                 v-model="queryStatus">
        <el-option v-for="status in scoringStatusList"
                   :key="status.value"
                   :label="status.name"
                   :value="status.value"></el-option>
      </el-select>
      <el-select placeholder="请选择公开课类型"
                 class="picker"
                 v-model="queryOpenClassType">
        <el-option v-for="openClassType in openClassTypeList"
                   :key="openClassType.value"
                   :value="openClassType.value"
                   :label="openClassType.name"></el-option>
      </el-select>
      <el-button type="primary"
                 @click="tableTypeChange">查 询</el-button>
    </div>
    <!-- </div> -->
    <el-table :data="openClassTable">
      <el-table-column align="center"
                       key="1"
                       label="学期"
                       prop="semester"></el-table-column>
      <el-table-column align="center"
                       key="2"
                       label="公开课类型"
                       prop="typeStr"></el-table-column>
      <el-table-column align="center"
                       key="3"
                       label="公开课讲师"
                       prop="opername"></el-table-column>
      <el-table-column align="center"
                       key="4"
                       label="公开课名称"
                       prop="name"></el-table-column>
      <el-table-column align="center"
                       key="5"
                       label="开课时间"
                       prop="course_time"></el-table-column>
      <el-table-column align="center"
                       v-if="tableType===2"
                       key="20"
                       label="教室"
                       prop="room"></el-table-column>
      <el-table-column align="center"
                       key="10"
                       label="分数"
                       prop="score"></el-table-column>
      <el-table-column align="center"
                       key="6"
                       v-if="tableType===1"
                       label="状态"
                       prop="statusStr"></el-table-column>
      <el-table-column align="center"
                       key="11"
                       v-if="tableType===2"
                       label="状态"
                       prop="check_statusStr"></el-table-column>
      <el-table-column align="center"
                       key="7"
                       :width="roleType===2&&tableType===1?'220px':'200px'"
                       label="操作">
        <template slot-scope="scope">
          <div v-if="roleType===2&&tableType===1">
            <el-button type="primary"
                       size="mini"
                       :disabled="scope.row.status!==1"
                       @click="editOpenClass(scope.row,0)">编辑</el-button>
            <el-button type="warning"
                       size="mini"
                       @click="editOpenClass(scope.row,1)">详情</el-button>
            <el-button type="danger"
                       @click="deleteOpenClass(scope.$index,scope.row.id)"
                       size="mini">删除</el-button>
          </div>
          <div v-if="roleType===1&&tableType===1">
            <el-button type="primary"
                       @click="editOpenClass(scope.row,1)">查看</el-button>
            <el-button type="warning"
                       @click="viewScoringDetail(scope.row)">评分情况</el-button>
          </div>
          <div v-if="tableType===2">
            <el-button type="primary"
                       @click="viewScoring">查 看</el-button>
            <el-button type="warning"
                       :disabled="scope.row.check_status!==1&&scope.row.status===0"
                       @click="scoring(scope.row.id)">评 分</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="openClassVisible"
               title="添加公开课"
               width="600px">
      <el-form label-width="120px"
               ref="openClassForm"
               class="openClassForm"
               :rules="openClassFormRules"
               :model="openClassForm">
        <el-form-item label="公开课类型"
                      prop="openClassType">
          <el-select v-model="openClassForm.openClassType"
                     :disabled="disabled"
                     @change="teacherChange">
            <el-option v-for="openClassType in openClassTypeList"
                       :key="openClassType.value"
                       :value="openClassType.value"
                       :label="openClassType.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学科"
                      prop="courseId">
          <el-select v-model="openClassForm.courseId"
                     :disabled="disabled">
            <el-option v-for="course in courseList"
                       :key="course.id"
                       :value="course.id"
                       :label="course.course"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公开课名称"
                      prop="openClassName">
          <el-input v-model="openClassForm.openClassName"
                    :disabled="disabled"></el-input>
        </el-form-item>
        <el-form-item label="公开课简介"
                      prop="openClassIntroduce">
          <el-input v-model="openClassForm.openClassIntroduce"
                    type="textarea"
                    :disabled="disabled"
                    autosize></el-input>
        </el-form-item>
        <el-form-item label="时间"
                      prop="time">
          <el-date-picker v-model="openClassForm.time"
                          @change="teacherChange"
                          :disabled="disabled"
                          placeholder="选择开始时间"
                          value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="班级"
                      prop="classId">
          <el-cascader v-model="openClassForm.classId"
                       :disabled="disabled"
                       :options="gradeList"></el-cascader>
        </el-form-item>
        <el-form-item label="节次"
                      prop="session">
          <el-select v-model="openClassForm.session"
                     :disabled="disabled"
                     @change="teacherChange">
            <el-option v-for="session in sessionList"
                       :key="session.sort"
                       :value="session.sort"
                       :label="session.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="教室"
                      prop="classroom">
          <el-select v-model="openClassForm.classroom"
                     :disabled="disabled">
            <el-option v-for="classroom in classroomList"
                       :key="classroom.id"
                       :value="classroom.id"
                       :label="classroom.room_name"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label='校内听课教师'
                      prop="inSchoolTeacher">
          <el-select v-model="openClassForm.inSchoolTeacher"
                     :disabled="disabled"
                     clearable
                     multiple
                     filterable
                     placeholder="请选择教师">
            <el-option v-for="teacher in teacherList"
                       :key="teacher.id"
                       :value="teacher.id"
                       :label="teacher.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label='校外听课教师'
                      prop="outSchoolTeacher">
          <el-select v-model="openClassForm.outSchoolTeacher"
                     :disabled="disabled||openClassForm.openClassType!==2"
                     clearable
                     filterable
                     multiple
                     placeholder="请选择教师">
            <el-option v-for="teacher in teacherList"
                       :key="teacher.id"
                       :value="teacher.id"
                       :label="teacher.name"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="理由"
                      prop="reason">
          <el-input v-model="openClassForm.reason"
                    :disabled="disabled"
                    type="textarea"
                    autosize></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="openClassVisible=false">取 消</el-button>
        <el-button type="primary"
                   v-if="!disabled"
                   @click="addOpenClassConfirm">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="教师课时排名"
               :visible.sync="teacherClassRankingVisible"
               width="600px">
      <el-table :data="teacherClassRankingList"
                class="openClassForm">
        <el-table-column label="公开课讲师"
                         key="8"
                         align="center"
                         prop="manager_name"></el-table-column>
        <el-table-column label="校内外"
                         key="19"
                         align="center"
                         prop="type"></el-table-column>
        <el-table-column label="节次"
                         key="9"
                         align="center"
                         prop="sort_hour"></el-table-column>
      </el-table>
      <el-pagination layout="prev,pager,next"
                   background
                   :page-count="page.pageCount"
                   :page-size="page.currentPage"
                   @current-change="teacherClassRanking(2,$event)"></el-pagination>

      <div slot="footer">
        <el-button type="primary"
                   @click="teacherClassRankingVisible=false">确定</el-button>
      </div>
    </el-dialog>
    
    <el-dialog :visible.sync="scoringVisible"
               title="评分"
               width="800px">
      <div class="table-height">
        <table border="1"
               class="table-box">
          <tr>
            <th>对象</th>
            <th width="6% ">权重</th>
            <th>要素</th>
            <th>具体要求描述</th>
            <th>分值</th>
            <th>单项得分</th>
          </tr>
          <tr>
            <td rowspan="6">学生</td>
            <td rowspan="6">40</td>
            <td rowspan="3">参与状态22分</td>
            <td>态度：星期浓厚，主动积极</td>
            <td>8</td>
            <td>
              <el-input :min="1"
                        type="number"
                        :disabled="disabled"
                        @change="thisMaxValue(8, 0)"
                        v-model="scoringList[0]"> </el-input>
            </td>
          </tr>
          <tr>
            <td>广度：全员投入，参与面广</td>
            <td>8</td>
            <td>
              <el-input :min="1"
                        type="number"
                        :disabled="disabled"
                        @change="thisMaxValue(8,1)"
                        v-model="scoringList[1]"> </el-input>
            </td>
          </tr>
          <tr>
            <td>深度：思维活跃，个性表达</td>
            <td>6</td>
            <td>
              <el-input :min="1"
                        type="number"
                        :disabled="disabled"
                        @change="thisMaxValue(6,2)"
                        v-model="scoringList[2]"> </el-input>
            </td>
          </tr>
          <tr>
            <td rowspan="3">达成状态18分</td>
            <td>目标达成：目标各异，达成度高</td>
            <td>8</td>
            <td>
              <el-input :min="1"
                        type="number"
                        :disabled="disabled"
                        @change="thisMaxValue(8,3)"
                        v-model="scoringList[3]"> </el-input>
            </td>
          </tr>
          <tr>
            <td>个性养成：主动参与，自主发展</td>
            <td>5</td>
            <td>
              <el-input :min="1"
                        type="number"
                        :disabled="disabled"
                        @change="thisMaxValue(5,4)"
                        v-model="scoringList[4]"> </el-input>
            </td>
          </tr>
          <tr>
            <td>心理体验：感受乐趣，热爱生活</td>
            <td>5</td>
            <td>
              <el-input :min="1"
                        type="number"
                        :disabled="disabled"
                        @change="thisMaxValue(5,5)"
                        v-model="scoringList[5]"> </el-input>
            </td>
          </tr>

          <tr>
            <td rowspan="7">教师</td>
            <td rowspan="7">40</td>
            <td rowspan="4">设计实施24分</td>
            <td>目标：有针对性、个性化、合理、具体、明确、预设性与生成性相结合</td>
            <td>6</td>
            <td>
              <el-input :min="1"
                        type="number"
                        :disabled="disabled"
                        @change="thisMaxValue(6,6)"
                        v-model="scoringList[6]"> </el-input>
            </td>
          </tr>
          <tr>
            <td>内容：正确、适量、重点突出，适合学生特点，不同层次的教学资源有效整合</td>
            <td>6</td>
            <td>
              <el-input :min="1"
                        type="number"
                        :disabled="disabled"
                        @change="thisMaxValue(6,7)"
                        v-model="scoringList[7]"> </el-input>
            </td>
          </tr>
          <tr>
            <td>组织：环节合理、教学民主、指导学法、反馈及时</td>
            <td>6</td>
            <td>
              <el-input :min="1"
                        :disabled="disabled"
                        type="number"
                        @change="thisMaxValue(6,8)"
                        v-model="scoringList[8]"> </el-input>
            </td>
          </tr>
          <tr>
            <td>方法：高效、熟练、综合</td>
            <td>6</td>
            <td>
              <el-input :min="1"
                        type="number"
                        :disabled="disabled"
                        @change="thisMaxValue(6,9)"
                        v-model="scoringList[9]"> </el-input>
            </td>
          </tr>
          <tr>
            <td rowspan="3">基本素养16分</td>
            <td>状态：大方自然，情感充沛</td>
            <td>5</td>
            <td>
              <el-input :min="1"
                        type="number"
                        :disabled="disabled"
                        @change="thisMaxValue(5,10)"
                        v-model="scoringList[10]"> </el-input>
            </td>
          </tr>
          <tr>
            <td>语言：简明清晰，文明规范</td>
            <td>6</td>
            <td>
              <el-input :min="1"
                        type="number"
                        :disabled="disabled"
                        @change="thisMaxValue(6,11)"
                        v-model="scoringList[11]"> </el-input>
            </td>
          </tr>
          <tr>
            <td>板书：清楚整洁，有启发性</td>
            <td>5</td>
            <td>
              <el-input :min="1"
                        :disabled="disabled"
                        type="number"
                        @change="thisMaxValue(5,12)"
                        v-model="scoringList[12]"> </el-input>
            </td>
          </tr>

          <tr>
            <td rowspan="3">学境</td>
            <td rowspan="3">20</td>
            <td>环境</td>
            <td>教师布置：雅致得体，突显特色</td>
            <td>4</td>
            <td>
              <el-input :min="1"
                        :disabled="disabled"
                        type="number"
                        @change="thisMaxValue(4,13)"
                        v-model="scoringList[13]"> </el-input>
            </td>
          </tr>
          <tr>
            <td>教具</td>
            <td>教具运用：独具匠心，高效灵活</td>
            <td>8</td>
            <td>
              <el-input :min="1"
                        :disabled="disabled"
                        type="number"
                        @change="thisMaxValue(8,14)"
                        v-model="scoringList[14]"> </el-input>
            </td>
          </tr>
          <tr>
            <td>学具</td>
            <td>学具运用：适时恰当，学以致用</td>
            <td>8</td>
            <td>
              <el-input :min="1"
                        type="number"
                        :disabled="disabled"
                        @change="thisMaxValue(8,15)"
                        v-model="scoringList[15]"></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="5">合计</td>
            <td>{{allScoring}}</td>
          </tr>
          <tr>
            <td>综合评价</td>
            <td colspan="5">
              <el-input type="textarea"
                        autosize
                        :disabled="disabled"
                        v-model="comprehensiveEvaluation"></el-input>
            </td>
          </tr>
        </table>
      </div>
      <div slot="footer">
        <el-button @click="scoringCancel">取 消</el-button>
        <el-button type="primary"
                   v-if="!disabled"
                   @click="scoringConfirm">确 定</el-button>
      </div>
    </el-dialog>
    <el-pagination layout="prev,pager,next"
                   background
                   :page-count="page.pageCount"
                   :page-size="page.currentPage"
                   @current-change="loadOpenClass"></el-pagination>
  </div>
</template>

<script>
import TermSelector from "@/components/selectors/termSelector";
export default {
  name: "openClass",
  components: { TermSelector },
  data() {
    return {
      tableType: 1,
      statusList: [
        { name: "发布成功", value: 1 },
        { name: "已完成", value: 2 },
        { name: "评分中", value: 0 },
        { name: "全部", value: "" },
      ],
      scoringStatusList: [
        { name: "已完成", value: 2 },
        { name: "未完成", value: 1 },
        { name: "全部", value: "" },
      ],
      queryStatus: "",
      queryTime: "",
      querySemester: "",
      queryOpenClassType: "",
      page: { currentPage: 1, pageCount: 0 },
      roleType: 2, //1管理员，2普通教师
      moduleManagementList: JSON.parse(sessionStorage.getItem("userInfo")).nav1,
      openClassTypeList: [
        { name: "校内公开课", value: 1 },
        { name: "校外公开课", value: 2 },
        { name: "组内公开课", value: 3 },
      ],
      teacherList: [],
      sessionList: [],
      gradeList: [],
      courseList: [],
      classroomList: [],
      openClassTable: [],
      openClassVisible: false,
      openClassForm: {
        openClassType: 1,
        courseId: "",
        openClassName: "",
        openClassIntroduce: "",
        time: "",
        classId: "",
        session: 1,
        classroom: "",
        inSchoolTeacher: [],
        outSchoolTeacher: [],
        reason: "",
      },
      openClassFormRules: {
        openClassType: [
          { required: true, message: "请选择公开课类型", trigger: "change" },
        ],
        courseId: [
          { required: true, message: "请选择学科", trigger: "change" },
        ],
        openClassName: [
          { required: true, message: "请输入公开课名称", trigger: "blur" },
        ],
        time: [{ required: true, message: "请选择时间", trigger: "blur" }],
        classId: [{ required: true, message: "请选择班级", trigger: "change" }],
        session: [{ required: true, message: "请选择节次", trigger: "change" }],
        classroom: [
          { required: true, message: "请选择教室", trigger: "change" },
        ],
        inSchoolTeacher: [
          {
            type: "array",
            required: true,
            message: "请选择校内听课教师",
            trigger: "change",
          },
        ],
        outSchoolTeacher: [
          {
            type: "array",
            required: false,
            message: "请选择校外听课教师",
            trigger: "change",
          },
        ],
      },
      disabled: false,
      teacherClassRankingVisible: false,
      teacherClassRankingList: [],
      scoringVisible: false,
      scoringList: [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      allScoring: 0,
      comprehensiveEvaluation: "",
      currentOpenClassId: "",
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      is_no: JSON.parse(sessionStorage.getItem("userInfo")).is_no,
    };
  },
  created() {
    if (this.is_no === 2) {
      this.tableType = 2;
    }
    //1302公开课页面
    for (let j = 0; j < this.moduleManagementList.length; j++) {
      if (this.moduleManagementList[j].id === 1302) {
        this.roleType = 1;
      }
    }
    this.loadOpenClass(1);
    this.loadSession();
    this.loadAllClass();
    this.loadClassroom();
    this.loadCourse();
    this.loadTeacher();
  },
  methods: {
    //   公开课管理
    loadOpenClass(page) {
      let data = {
        indexNo: page,
        status: this.queryStatus,
        type: this.queryOpenClassType,
        yearid: this.querySemester,
        is_role: this.roleType,
      };
      if (this.queryTime) {
        data.begin_time = this.queryTime[0];
        data.end_time = this.queryTime[1];
      }
      this.$post("/base/queryOpenClass.do", data)
        .then((res) => {
          this.openClassTable = res.data.rows;
          this.page.currentPage = res.data.indexNO;
          this.page.pageCount = res.data.pageCount;
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    // 公开课评分
    loadOpenClassScoring(page) {
      this.$post("/base/queryOpenClassByCheck.do", {
        indexNo: page,
        status: this.queryStatus,
        type: this.queryOpenClassType,
        begin_time: this.queryTime[0],
        end_time: this.queryTime[1],
        yearid: this.querySemester,
      })
        .then((res) => {
          this.openClassTable = res.data.rows;
          this.page.currentPage = res.data.indexNO;
          this.page.pageCount = res.data.pageCount;
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    // 查询所有有效班级
    loadAllClass() {
      const that = this;
      this.$post("/media/queryClassesByYear.do", {
        type: this.roleType,
      })
        .then((res) => {
          let gradeList = [];
          res.data.forEach((grade) => {
            let children = [];
            grade.classlist.forEach((cls) => {
              children.push({ value: cls.id, label: cls.cname });
            });
            gradeList.push({ value: grade.id, label: grade.gname, children });
          });
          that.gradeList = gradeList;
        })
        .catch((err) => {
          that.$message.error(err.message);
        });
    },
    //查询节次
    loadSession() {
      this.$get("/course/setting/queryCourseSetting", {})
        .then((res) => {
          this.sessionList = res.data;
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    //查询学科
    loadCourse() {
      this.$get("/media/queryCourse.do", {})
        .then((res) => {
          this.courseList = res.data;
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    //查询教室
    loadClassroom() {
      this.$get("/base/queryClassroom.do", {})
        .then((res) => {
          this.classroomList = res.data;
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    // 查询听课教师
    loadTeacher() {
      this.$post("/base/queryOpenClassTea.do", {
        course_time: this.openClassForm.time,
        sort_hour: this.openClassForm.session,
        type: this.openClassForm.openClassType,
      })
        .then((res) => {
          this.teacherList = res.data;
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    querySemesterChange(value) {
      this.querySemester = value;
    },
    addOpenClassClick() {
      this.disabled = false;
      this.openClassVisible = true;
      this.$nextTick(() => {
        this.$refs["openClassForm"].resetFields();
        this.openClassForm.id = "";
      });
    },
    teacherChange() {
      if (this.openClassForm.openClassType === 2) {
        this.openClassFormRules.outSchoolTeacher[0].required = true;
      } else {
        this.openClassFormRules.outSchoolTeacher[0].required = false;
      }
      this.openClassForm.outSchoolTeacher = [];
      this.openClassForm.inSchoolTeacher = [];
      this.loadTeacher();
    },
    // 确定添加公开课
    addOpenClassConfirm() {
      let inSchoolListeningTeacher = [];
      let outSchoolListeningTeacher = [];
      this.openClassForm.inSchoolTeacher.forEach((isTeacher) => {
        let newTeacher = {};
        newTeacher.manager_id = isTeacher;
        newTeacher.type = 1;
        inSchoolListeningTeacher.push(newTeacher);
      });
      this.openClassForm.outSchoolTeacher.forEach((osTeacher) => {
        let newOutTeacher = {};
        newOutTeacher.manager_id = osTeacher;
        newOutTeacher.type = 2;
        outSchoolListeningTeacher.push(newOutTeacher);
      });
      let apiUrl = "/base/insertOpenClass.do";
      let data = {
        course_id: this.openClassForm.courseId,
        name: this.openClassForm.openClassName,
        classid: this.openClassForm.classId[1],
        content: this.openClassForm.openClassIntroduce,
        course_time: this.openClassForm.time,
        sort_hour: this.openClassForm.session,
        room_id: this.openClassForm.classroom,
        reason: this.openClassForm.reason,
        // in_teachers: inSchoolListeningTeacher,
        // out_teachers: outSchoolListeningTeacher,
        type: this.openClassForm.openClassType,
      };
      if (this.openClassForm.id !== "") {
        data.id = this.openClassForm.id;
        apiUrl = "/base/updateOpenClass.do";
      }
      this.$refs["openClassForm"].validate((valid) => {
        if (valid) {
          this.$confirm("是否添加公开课", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$postJson(apiUrl, data)
                .then((res) => {
                  this.$message({
                    type: "success",
                    message: res.message,
                  });
                  this.openClassVisible = false;
                  this.tableTypeChange();
                })
                .catch((err) => {
                  this.$message({
                    type: "warning",
                    message: err.message,
                  });
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消添加",
              });
            });
        } else {
          return false;
        }
      });
    },
    // 导出公开课
    exporOpenClass() {
      const that = this;
      let paramas = new FormData();
      paramas.append("status", this.queryStatus);
      paramas.append("type", this.queryOpenClassType);
      if (this.queryTime) {
        paramas.append("begin_time", this.queryTime[0]);
        paramas.append("end_time", this.queryTime[1]);
      }
      paramas.append("yearid", this.querySemester);
      paramas.append("is_role", this.roleType);
      this.$postFile("/base/exportOpenClass.do", paramas)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "公开课";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
    // 查看编辑加载数据
    editOpenClass(row, type = 0) {
      if (type === 1) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
      this.openClassVisible = true;
      this.$nextTick(() => {
        let teacher = [];
        if (row.in_teachers.length !== 0) {
          row.in_teachers.forEach((inSchoolTeacher) => {
            teacher.push(inSchoolTeacher.manager_id);
          });
        }
        if (row.out_teachers.length !== 0) {
          row.out_teachers.forEach((outSchoolTeacher) => {
            teacher.push(outSchoolTeacher.manager_id);
          });
        }
        this.openClassForm.id = row.id;
        this.openClassForm.openClassType = row.type;
        this.openClassForm.courseId = row.course_id;
        this.openClassForm.openClassName = row.name;
        this.openClassForm.openClassIntroduce = row.content;
        this.openClassForm.time = row.course_time;
        this.openClassForm.classId = row.classid;
        this.openClassForm.session = row.sort_hour;
        this.openClassForm.classroom = row.room_id;
        this.openClassForm.reason = row.reason;
        this.loadTeacher();
        this.openClassForm.teacher = teacher;
      });
    },
    // 教师课时排名
    teacherClassRanking(type,page) {
      let apiUrl = "/base/queryOpenClassTeaRank.do";
      if (type === 2) {
        apiUrl = "/base/queryOpenClassTeaListen.do";
      }
      this.$post(apiUrl, {
        yearid: this.querySemester,
        indexNo:page
      })
        .then((res) => {
          this.page.currentPage = res.data.indexNO
          this.page.pageCount = res.data.pageCount
          
          this.teacherClassRankingList = res.data.rows;
          // this.teacherClassRankingList = res.data;
          
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
      this.teacherClassRankingVisible = true;
    },
    // 管理员查看评分情况
    viewScoringDetail(row) {
      this.$router.push({
        name: "openClassScoring",
        query: { id: row.id },
      });
    },
    tableTypeChange() {
      if (this.tableType === 1) {
        this.loadOpenClass(1);
      } else {
        this.loadOpenClassScoring(1);
      }
    },
    // 点击评分
    scoring(id) {
      this.scoringList = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];
      this.allScoring = 0;
      this.currentOpenClassId = id;
      this.comprehensiveEvaluation = "";
      this.disabled = false;
      this.scoringVisible = true;
    },
    // 取消提交分数
    scoringCancel() {
      this.scoringList = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];
      this.allScoring = 0;
      this.comprehensiveEvaluation = "";
      this.scoringVisible = false;
    },
    // 提交评分
    scoringConfirm() {
      let newScoring = [];
      for (let i = 0; i < this.scoringList.length; i++) {
        if (!this.scoringList[i]) {
          this.$message({
            type: "warning",
            message: "请全部填写",
          });
          return;
        } else {
          let newObject = {};
          newObject.sort_index = i + 1;
          newObject.score = this.scoringList[i];
          newScoring.push(newObject);
        }
      }
      let data = {
        open_id: this.currentOpenClassId,
        tea_id: this.userInfo.userid,
        opinion: this.comprehensiveEvaluation,
        list: newScoring,
      };
      this.$confirm("是否评分", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$postJson("/base/insertOpenClassCheckScore.do", data)
            .then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.scoringVisible = false;
              this.tableTypeChange();
            })
            .catch((err) => {
              this.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消评分",
          });
        });
    },
    // 评分最大值
    thisMaxValue(maxNumber, value) {
      if (this.scoringList[value] > maxNumber) {
        this.scoringList[value] = maxNumber;
      }
      if (this.scoringList[value] < 0) {
        this.scoringList[value] = 0;
      }
      this.allScoring = 0;
      for (let i = 0; i < this.scoringList.length; i++) {
        if (this.scoringList[i]) {
          this.allScoring = this.allScoring + Number(this.scoringList[i]);
        }
      }
    },
    // 查看评分
    viewScoring() {
      this.disabled = true;
      this.$post("/base/queryOpenClassCheckScore.do", {
        tea_id: this.userInfo.userid,
      })
        .then((res) => {
          this.allScoring = 0;
          res.data.forEach((scoring) => {
            this.scoringList[scoring.sort_index - 1] = scoring.score;
            this.allScoring = this.allScoring + Number(scoring.score);
          });

          this.scoringVisible = true;
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    deleteOpenClass(index, id) {
      this.$confirm("是否删除该数据", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/deleteOpenClass.do", { id })
            .then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.openClassTable.splice(index, 1);
            })
            .catch((err) => {
              this.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style scoped lang="scss">
.query-header {
  margin-top: 20px;
}
.query-header-button {
  width: 250px;
  display: flex;
  margin: 20px 0;
}
.openClassForm {
  height: 500px;
  overflow-x: auto;
  width: 500px;
  margin: 0 10px;
}
.table-height {
  height: 500px;
  overflow-x: auto;
}
.table-box {
  text-align: center;
  width: 100%;
}

.table-box td {
  padding: 10px;
}

.table-box p {
  margin: 5px 0;
}
.picker {
  margin: 0 5px;
}
</style>
